<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="field vdatepicker control has-icons-right">
              <v-date-picker
                class="calendar_input"
                clearable
                is-range
                mode='range'
                v-model='dateFilter'
                :input-props='{ placeholder: "Fecha" }'
                @input="getListByDate"
              />
              <span class="icon is-right">
                <span class="material-icons text_primary_green">today</span>
              </span>
            </div>
          </div>
        </div>
        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <div class="field control has-icons-right">
              <input
                class="input input_reset"
                type="text"
                placeholder="Buscador"
                v-model="searchText"
                @input="searchUser"
              >
              <span class="icon is-right">
                <span class="material-icons">search</span>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="column is-12 is_grid">
      <div class="table-container">
        <base-table
          :columns="columnsData"
          :data="messageList"
          :loading=loading
        >
          <div slot="name" slot-scope="scope">
            <a class="link" @click="$emit('show-modal-publication', scope.row)">
              {{ scope.row.user_name }}
            </a>
          </div>
          <div slot="status" slot-scope="scope">
            <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
              {{ scope.row.active ? 'Activo' : 'Inactivo' }}
            </p>
          </div>
        </base-table>
      </div>
    </div>
    <div class="column is-12">
      <base-pagination
        :page="page"
        :pages="parseInt(pages)"
        :action="paginator"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'PublicationMessageMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  data () {
    return {
      loading: false,
      page: 1,
      pages: 1,
      dateFilter: {},
      searchText: '',
      columnsData: [
        { id: 'name', name: 'name', header: 'Nombre' },
        { id: 'commentary', header: 'Mensaje', accessor: (row) => `${row.commentary.substring(0, 100)} ...` },
        { id: 'publication_name', header: 'Publicación' },
        { id: 'email', header: 'Correo electrónico' },
        { id: 'created_at', header: 'Fecha', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' }
      ],
      messageList: []
    }
  },
  methods: {
    ...mapActions(['getPublicationsComments', 'updateStatusMessagePublication']),
    async getComments (page, search, date) {
      let startDate = ''
      let endDate = ''
      if (date) {
        startDate = moment(this.dateFilter.start).format('YYYY-MM-DD 00:00:00')
        endDate = moment(this.dateFilter.end).format('YYYY-MM-DD 23:59:59')
      }
      const { comments, total, totalPages } = await this.getPublicationsComments({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search, startDate: startDate, endDate: endDate })
      this.total = total
      this.pages = totalPages
      this.messageList = comments
      this.loading = false
    },
    searchUser ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.loading = true
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push(
          { name: 'mensajes', query: { page: this.page, search: target.value } }
        ).catch(() => {})
        this.getComments(this.page, target.value, this.dateFilter)
      }
    },
    getListByDate () {
      this.loading = true
      this.getComments(this.page, this.searchText, this.dateFilter)
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'mensajes', query: { page: this.page, search: this.searchText } })
      this.getComments(this.page, this.searchText, this.dateFilter)
    },
    async updateStatusMessagePublicationAction (idMessage) {
      const resp = await this.updateStatusMessagePublication(idMessage)
      if (resp) {
        this.getComments(this.page, this.searchText, this.dateFilter)
      }
      return resp
    }
  },
  beforeMount () {
    this.loading = true
    const { page, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.dateFilter = ''
    this.getComments(this.page, this.searchText, this.dateFilter)
  }
}
</script>

<style lang="scss" scoped>
  .vdatepicker {
    width: 250px;
  }

  .level-item {
    justify-content: flex-start;
  }

  .link {
    cursor: pointer;
    color: var(--primary-green);
  }
  .link:hover {
    cursor: pointer;
    color: var(--primary-green);
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select, input {
      width: 100%;
    }
  }
</style>
